<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Estatísticas totais</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <!-- Usuarios -->
        <b-col md="3" sm="6" class="mb-2 mb-md-0" :class="'mb-2 mb-xl-0'">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="'light-primary'">
                <feather-icon size="24" :icon="'TrendingUpIcon'" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.totalUser }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Usuários cadastrados </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Tomadores -->
        <b-col md="3" sm="6" class="mb-2 mb-md-0" :class="'mb-2 mb-xl-0'">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="'light-info'">
                <feather-icon size="24" :icon="'UserIcon'" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.totalTomador }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Tomadores cadastrados </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Orgaos -->
        <b-col md="3" sm="6" class="mb-2 mb-sm-0" :class="'mb-2 mb-xl-0'">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="'light-danger'">
                <feather-icon size="24" :icon="'CommandIcon'" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.totalOrgao }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Órgãos cadastrados </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <!-- Simulacoes -->
        <b-col md="3" sm="6" class="mb-2 mb-md-0" :class="''">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="'light-success'">
                <feather-icon size="24" :icon="'ArchiveIcon'" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ this.totalSimulacao }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> Simulações realizadas </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
  import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 25,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
        totalUser: 0,
        totalTomador: 0,
        totalOrgao: 0,
        totalSimulacao: 0,
        isBusy: false,
      }
    },
    mounted() {
      this.carregarGrid()
      this.$root.$on('atualizarGraficos1', (item) => {
        this.userData = this.$jwtDecode(localStorage.getItem('userData')).userData
        this.carregarGrid()
      })
    },
    methods: {
      carregarGrid() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .pesquisar('utils/GetAnalisesAdm', {
              institutoSelecionado: this.userData.institutoSelecionado,
            })
            .then((response) => {
              if (response.data.status == 'erro') {
                this.isBusy = false
                console.error(response.data.message)
                this.$message.error(response.data.message)
                return
              }

              this.isBusy = false
              this.totalUser = response.data.dadosUser
              this.totalTomador = response.data.dadosTomador
              this.totalOrgao = response.data.dadosOrgao
              this.totalSimulacao = response.data.dadosSimulacao
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
    },
  }
</script>
